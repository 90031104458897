
import { CheckoutQuote } from '@/models/dto/Quote'
import CUCheckbox from '@/components/CUCheckbox.vue'
import { currencyFilter } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'
import CheckoutTermsModal from '@/components/CheckoutTermsModal.vue'
import { EventBus } from '@/utils/eventBus'
import quotes from '@/services/quotes'
import { Terms } from '@/models/dto/Terms'
import { PaymentTypeIdKeys } from '@/models/dto'
import dayjs from 'dayjs'
import { CheckoutDocument } from '@/models/dto/CheckoutDocument'

@Component({
  components: {
    CUCheckbox,
    CheckoutTermsModal,
  },
})
export default class CheckoutPayLater extends Vue {
  @Prop({ required: true }) quote: CheckoutQuote
  @Prop({ required: true }) terms: Terms[]
  @Prop({ type: Boolean, default: false }) isZeroCheckout: boolean

  currencyFilter = currencyFilter

  termsAccepted = false
  showModal = false
  isSubmitting = false
  termsError = false
  fullName = ''
  email = ''

  get confirmationEmails(): string[] {
    return this.quote?.customer?.email ? [this.quote.customer.email] : []
  }

  clickSubmit(): void {
    if (this.quote?.requireSignatureUponCheckout) {
      this.showModal = true
      return
    }
    this.handleSubmit()
  }

  async handleSubmit(): Promise<void> {
    this.isSubmitting = true
    if (!this.termsAccepted) {
      this.termsError = true
      this.isSubmitting = false
      return
    }

    try {
      let checkoutDocument: CheckoutDocument = null
      if (this.quote?.requireSignatureUponCheckout) {
        checkoutDocument = {
          signatureName: this.fullName,
          signatureEmail: this.email,
          signatureTimestamp: dayjs.utc().toISOString(),
        }
      }

      const res = await quotes.convertManually({
        quoteId: this.quote.quoteId,
        payment_method: null,
        payment_type: PaymentTypeIdKeys.BILL_AFTER_SERVICES,
        amountPaid: 0,
        confirmationEmails: this.confirmationEmails,
        checkoutDocument,
        enablePONumber: this.quote.enablePONumber,
        poNumber: this.quote.poNumber,
      })

      if (res.status === 200) {
        EventBus.$emit('checkout:convert-quote-success')
      }
    } catch (error) {
      console.error('Error while submitting:', error)
    } finally {
      this.isSubmitting = false
    }
  }

  handleCheckboxInput(input: boolean): void {
    this.termsAccepted = input
    this.termsError = false
  }

  handleCloseTerms(): void {
    this.showModal = false
  }

  handleAcceptTerms(fullName: string, email: string): void {
    this.showModal = false
    this.termsAccepted = true
    this.fullName = fullName
    this.email = email
    this.termsError = false
    if (this.quote.requireSignatureUponCheckout) {
      this.handleSubmit()
    }
  }

  handleCancelTerms(): void {
    this.termsError = false
    this.showModal = false
    this.termsAccepted = false
  }
}
