export interface Palette {
  foreground: string
  background: string
}

// eslint-disable-next-line no-redeclare
export class Palette implements Palette {
  constructor(palette: Partial<Palette>) {
    Object.assign(this, palette)
  }

  public static of(foreground: string, background: string): Palette {
    return new Palette({ foreground, background })
  }
}
