
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CheckoutStatus extends Vue {
  @Prop({ required: true }) header: string
  @Prop({ required: true }) subheader: string
  @Prop({ required: true }) isExpired: boolean
  @Prop({ required: true }) isSoldOut: boolean
  @Prop({ required: true }) isConversionError: boolean
  @Prop({ required: false, default: false, type: Boolean }) loading: boolean
  @Prop({ required: false, default: 'primary', type: String }) color: string

  get isWarning(): boolean {
    return this.isExpired || this.isSoldOut || this.isConversionError
  }

  get headerText(): string {
    if (this.isExpired) {
      return 'This quote has expired'
    } else if (this.isSoldOut) {
      return 'This quote is no longer available'
    } else if (this.isConversionError) {
      return 'There was an error converting your quote'
    }

    return this.header
  }

  get subheadingText(): string {
    if (this.isExpired) {
      return 'Please contact us to renew this quote and confirm our availability.'
    } else if (this.isSoldOut) {
      return "We are now sold out on the date(s) of your trip. Please contact us if you'd like a new quote for different dates."
    } else if (this.isConversionError) {
      return 'Your payment was successful, but we were unable to convert your quote to a booking. Please contact us for assistance.'
    }

    return this.subheader
  }
}
