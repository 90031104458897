
import { Vue, Component, Prop } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'
import { PaymentMethod } from '@/utils/enum'
import { states } from '@/utils/states'
import { CheckoutQuote } from '@/models/dto/Quote'
import CUCheckbox from '@/components/CUCheckbox.vue'
import CheckoutTermsModal from '@/components/CheckoutTermsModal.vue'
import { Terms } from '@/models/dto/Terms'

@Component({
  components: {
    CUCheckbox,
    CheckoutTermsModal,
  },
})
export default class CheckoutPayNow extends Vue {
  @Prop({ required: true }) readonly quote: CheckoutQuote
  @Prop({ required: true }) readonly terms: Terms[]
  @Prop({ required: true }) readonly fee: number
  @Prop({ required: true }) readonly totalDueNow: number
  @Prop({ required: true }) readonly allTripsDueNow: boolean
  @Prop({ type: Boolean, default: false }) readonly isSubmitting: boolean
  @Prop({ type: Boolean, default: false }) readonly isPaying: boolean
  @Prop({ type: Boolean, default: false }) readonly termsAccepted: boolean
  @Prop({ type: Boolean, default: false }) readonly termsError: boolean
  @Prop({ type: Boolean }) readonly payFullAmount: boolean

  currencyFilter = currencyFilter
  PaymentMethod = PaymentMethod
  states = states

  totalPaid: string = null
  totalPaidNum: number = null

  get color(): string {
    return this.quote.company.primaryColor
  }

  get requireSignature(): boolean {
    return this.quote.requireSignatureUponCheckout
  }

  get subTotal(): number {
    return this.payFullAmount
      ? this.quote.grandRecurringTotal
      : this.totalPaidNum
  }

  get subTotalFee(): number {
    return Math.round(this.subTotal * this.fee) / 100
  }

  clickSubmit(): void {
    this.$emit('payment:submit')
  }

  handlePaymentInputBlur(): void {
    this.totalPaidNum = parseFloat(this.totalPaid.replace(/[^\d.-]/g, ''))
    if (this.totalPaidNum < this.totalDueNow || !this.totalPaidNum) {
      this.totalPaidNum = this.totalDueNow
    } else if (this.totalPaidNum > this.quote.grandRecurringTotal) {
      this.totalPaidNum = this.quote.grandRecurringTotal
    }
    this.totalPaid = currencyFilter(this.totalPaidNum)
    return
  }

  mounted(): void {
    this.totalPaid = currencyFilter(this.totalDueNow)
    this.totalPaidNum = this.totalDueNow
  }
}
