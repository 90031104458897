
import { PaymentMethod, QuotePaymentMethodIds } from '@/models/dto'
import {
  PaymentTransaction,
  ReservationPaymentSummary,
} from '@/models/dto/PaymentTransaction'
import { currencyFilter, percentageFormatter } from '@/utils/string'
import currency from 'currency.js'
import { Vue, Component, Prop } from 'vue-property-decorator'

const CREDIT_CARD_ENTRY_TYPE = 1
const CHARGE_ENTRY_TYPE = 0
const PAYMENT_ENTRY_TYPE = 1

@Component({})
export default class CheckoutPaymentOverview extends Vue {
  @Prop({ required: true }) transactions!: ReservationPaymentSummary[]
  @Prop({ required: true }) paymentPolicy!: string
  @Prop({ required: true }) totalDueNow!: number
  @Prop({ required: true }) quoteTotal!: number
  @Prop({ required: true }) paymentTypeId!: number
  @Prop({ required: true }) quotePaymentMethods!: PaymentMethod[]

  currencyFilter = currencyFilter
  QuotePaymentMethodIds = QuotePaymentMethodIds

  get isManual(): boolean {
    return !this.paymentTypeId
  }

  get cardTransactions(): number {
    const cardTransaction = this.transactions.reduce((sum, trip) => {
      sum +=
        trip.paymentSummaryEntries.find(
          (t) => t.entryType === CREDIT_CARD_ENTRY_TYPE
        )?.amount || 0
      return sum
    }, 0)
    return cardTransaction
  }

  get cardTransactionDescription(): string {
    return (
      this.transactions[0]?.paymentSummaryEntries.find(
        (t) => t.entryType === PAYMENT_ENTRY_TYPE
      )?.description || ''
    )
  }

  get processingFeeAmount(): number {
    const fee = this.transactions.reduce((sum, trip) => {
      const paymentIdx = trip.paymentSummaryEntries.findIndex(
        (t) => t.entryType === CREDIT_CARD_ENTRY_TYPE
      )
      if (
        paymentIdx > 0 &&
        trip.paymentSummaryEntries[paymentIdx - 1].description ===
          'Processing Fees'
      ) {
        sum += trip.paymentSummaryEntries[paymentIdx - 1].charge
      }
      return sum
    }, 0)
    return fee
  }

  get fee(): number {
    return (
      this.quotePaymentMethods.find(
        (m) => m.paymentMethodTypeId === this.paymentTypeId
      )?.processingFee || null
    )
  }

  get paymentInfo(): { method: string; fee: string } {
    switch (this.paymentTypeId) {
      case QuotePaymentMethodIds.CREDIT_CARD:
        return {
          method: this.cardTransactionDescription,
          fee: currencyFilter(this.processingFeeAmount || 0),
        }
      case QuotePaymentMethodIds.ACH:
        if (this.cardTransactionDescription?.includes('Logged')) {
          return { method: 'Bank', fee: percentageFormatter(this.fee, 1) }
        }
        return {
          method: 'Bank',
          fee: currencyFilter(this.processingFeeAmount || 0),
        }
      case QuotePaymentMethodIds.CHECK:
        return { method: 'Check', fee: percentageFormatter(this.fee, 1) }
      case QuotePaymentMethodIds.WIRE:
        return { method: 'Wire', fee: percentageFormatter(this.fee, 1) }
      case QuotePaymentMethodIds.OTHER:
        return { method: 'Other', fee: percentageFormatter(this.fee, 1) }
      default:
        return { method: 'Manual', fee: null }
    }
  }

  get paymentAmount(): number {
    if (this.cardTransactions) {
      return this.cardTransactions - this.processingFeeAmount
    }
    return this.totalDueNow
  }

  get paymentTotal(): number {
    if (this.paymentTypeId === QuotePaymentMethodIds.ACH) {
      if (this.cardTransactionDescription?.includes('Logged')) {
        return null
      }
    }

    if (
      this.paymentTypeId !== QuotePaymentMethodIds.CREDIT_CARD &&
      this.paymentTypeId !== QuotePaymentMethodIds.ACH
    ) {
      return null
    }

    if (this.cardTransactions) {
      return this.cardTransactions
    }
    const fee = this.fee
      ? currency(this.totalDueNow).multiply(this.fee).divide(100).value
      : 0
    return currency(this.totalDueNow).add(fee).value
  }
}
