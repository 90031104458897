import { Type } from '.'
import { Palette } from '@/models/dto/Palette'
import { InvoiceStatus } from '../Invoice'
import { PaystubStatus } from '../DriverPay'

export interface Status extends Type {
  id: number
}

export enum QuoteStatusIds {
  LEAD = 1,
  SENT = 2,
  CONVERTED = 3,
  LOST = 4,
  DONT_QUOTE = 5,
  SOLD_OUT = 6,
}

// eslint-disable-next-line no-redeclare
export class Status implements Status {
  public static color(status: Status): Palette {
    switch (status && status.key) {
      case 'lost':
        return Palette.of('text-gray-2', 'bg-gray-1')
      case 'dont_quote':
        return Palette.of('text-gray-2', 'bg-gray-1')
      case 'sold_out':
        return Palette.of('additional-red', 'additional-red-2')
      case 'lead':
        return Palette.of('orange', 'orange-10')
      case 'sent':
        return Palette.of('purple', 'purple-10')
      case 'converted':
        return Palette.of('green', 'green-10')
      default:
        return Palette.of('black', 'gray-bg')
    }
  }
}

export class DriverPayStatus {
  public static color(status: InvoiceStatus | PaystubStatus): Palette {
    switch (status) {
      case 'DRAFT':
        return Palette.of('gray-text-dark', 'bg-gray-2')
      case 'REVIEWED':
        return Palette.of('additional-blue', 'additional-blue-2')
      case 'PENDING':
        return Palette.of('orange', 'orange-10')
      case 'PAID':
        return Palette.of('green', 'green-10')
      case 'REVERTED':
        return Palette.of('additional-red', 'additional-red-2')
      default:
        return Palette.of('black', 'gray-bg')
    }
  }
}
