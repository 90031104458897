
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CheckoutQuote } from '@/models/dto/Quote'
import { httpHelper, phoneFormatFilter } from '@/utils/string'
import { baseUrl, staticResource } from '@/utils/env'

@Component({})
export default class CheckoutAppBar extends Vue {
  @Prop({ required: true }) quote: CheckoutQuote

  phoneFormatFilter = phoneFormatFilter
  staticResource = staticResource
  baseUrl = baseUrl

  get title(): string {
    const type = this.quote.sendAsInvoice ? 'Invoice' : 'Quote'
    return `${type} ${this.quote.managedId}`
  }

  get hasWebsiteUrl(): boolean {
    return !!this.quote?.company?.websiteUrl
  }

  openWebsiteLink(): void {
    if (this.hasWebsiteUrl) {
      window.open(httpHelper(this.quote.company.websiteUrl), '_blank')
    }
  }
}
