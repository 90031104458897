
import { CheckoutQuote } from '@/models/dto/Quote'
import { currencyFilter } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CheckoutTotal extends Vue {
  @Prop({ required: true }) quote: CheckoutQuote

  currencyFilter = currencyFilter

  // TODO: edit on backend
  get overageRate(): { amount: number; label: string } {
    const rate = this.quote.trips[0]?.rates?.[0]
    return rate
      ? {
          amount: rate.amount,
          label: this.rateLabel[rate.rateType.id],
        }
      : null
  }

  get type(): string {
    return this.quote.sendAsInvoice ? 'Invoice' : 'Quote'
  }

  get rateLabel(): { [key: string]: string } {
    return {
      1: 'hour',
      2: 'day',
      3: 'mile',
    }
  }
}
