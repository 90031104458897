
import client from '@/services/quotes'
import { CheckoutQuote } from '@/models/dto/Quote'
import CUTabs from '@/components/CUTabs.vue'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { Tab } from '@/models/dto/Tab'
import CheckoutTripDetails from '@/components/CheckoutTripDetails.vue'
import CheckoutAppBar from '@/components/CheckoutAppBar.vue'
import CheckoutFooter from '@/components/CheckoutFooter.vue'
import CheckoutLoader from '@/components/CheckoutLoader.vue'
import CheckoutInformation from '@/components/CheckoutInformation.vue'
import CheckoutTotal from '@/components/CheckoutTotal.vue'
import CheckoutPayLater from '@/components/CheckoutPayLater.vue'
import CheckoutStatus from '@/components/CheckoutStatus.vue'
import CheckoutPaymentMethod from '@/components/CheckoutPaymentMethod.vue'
import { EventBus } from '@/utils/eventBus'
import { currencyFilter, htmlToString } from '@/utils/string'
import { isBeforeTodayTz } from '@/utils/date'
import goTo from 'vuetify/lib/services/goto'
import payments from '@/services/payments'
import { CompanyPaymentGatewayDetail } from '@/models/PaymentGateway'
import CheckoutPaymentOverview from '@/components/CheckoutPaymentOverview.vue'
import CUExpansionPanel from '@/components/CUExpansionPanel.vue'
import { Terms } from '@/models/dto/Terms'
import { PaymentMethodKeys, Trip } from '@/models/dto'
import {
  getRecurringAmountDueLater,
  getRecurringAmountDueNow,
} from '@/utils/trip'
import currency from 'currency.js'
import CUSnackbar from '@/components/CUSnackbar.vue'
import { timeZones } from '@/utils/time'
import auth from '@/store/modules/auth'
import { useFavicon } from '@vueuse/core'
import { staticResource } from '@/utils/env'
import { PaymentTransaction } from '@/models/dto/PaymentTransaction'
import { QuoteStatusIds } from '@/models/dto/Status'
import { v4 } from 'uuid'

@Component({
  components: {
    CUTabs,
    CUSnackbar,
    CheckoutAppBar,
    CheckoutFooter,
    CheckoutLoader,
    CheckoutInformation,
    CheckoutTotal,
    CheckoutStatus,
    CheckoutPayLater,
    CheckoutPaymentOverview,
    CUExpansionPanel,
  },
})
export default class Checkout extends mixins(DateMixin) {
  quote: CheckoutQuote = null
  switchValue = 'Pay Now'
  isConverted = false
  isConversionError = false
  statusHeader: string = null
  statusSubheader: string = null
  statusLoading = false
  defaultPaymentGateway: CompanyPaymentGatewayDetail = null
  getRecurringAmountDueLater = getRecurringAmountDueLater
  getRecurringAmountDueNow = getRecurringAmountDueNow
  currencyFilter = currencyFilter
  transactions: PaymentTransaction[] = []
  errorMessages: string = null
  displayErrorSnackbar = false
  terms: Terms[] = []
  editPONumber = false
  sessionId = null
  tabIndex = 0

  get paymentPolicy(): string {
    if (htmlToString(this.quote?.paymentPolicy)) {
      return this.quote?.paymentPolicy
    } else if (htmlToString(this.quote?.company?.defaultPaymentPolicy)) {
      return this.quote?.company?.defaultPaymentPolicy
    }
    return ''
  }

  get expirationTimezone(): { id: number; label: string; zoneName: string } {
    return (
      timeZones.find(
        (t) => t.zoneName === auth.getCompany?.address?.timeZone
      ) || timeZones[0]
    )
  }

  get isExpired(): boolean {
    return (
      isBeforeTodayTz(
        this.quote.expirationDate,
        this.expirationTimezone.zoneName
      ) &&
      this.quote.enableExpiration &&
      !this.quote.isConverted
    )
  }

  get isSoldOut(): boolean {
    return this.quote.quoteStatusId === QuoteStatusIds.SOLD_OUT
  }

  get expiration(): string {
    if (!this.quote.enableExpiration || !this.quote.expirationDate) {
      return ''
    }
    return this.formatShortDate(this.quote.expirationDate.split('+')[0])
  }

  get tripTabs(): Tab[] {
    const addresses = [].concat(
      ...this.quote.trips.map((trip) => {
        return trip.stops.map((stop) => stop.address.name)
      })
    )
    return this.quote.trips.map((trip, i) => {
      return {
        label: trip.routeName || `Trip ${i + 1}`,
        component: CheckoutTripDetails,
        props: {
          addresses,
          trip,
          color: this.quote.company.secondaryColor,
          index: i + 1,
        },
      }
    })
  }

  get paymentMethodTabs(): Tab[] {
    if (this.statusLoading) {
      return []
    }
    const order = {
      [PaymentMethodKeys.CREDIT_CARD]: 0,
      [PaymentMethodKeys.ACH]: 1,
      [PaymentMethodKeys.CHECK]: 2,
      [PaymentMethodKeys.WIRE]: 3,
      [PaymentMethodKeys.OTHER]: 4,
    }
    return this.quote?.paymentMethods
      .filter((m) => m.isActive)
      .map((method) => {
        return {
          label: method.paymentMethodType.label,
          key: method.paymentMethodType.key,
          component: CheckoutPaymentMethod,
          props: {
            quote: this.quote,
            terms: this.terms,
            method: method.paymentMethodType.key,
            totalDueNow: this.totalDueNow,
            allTripsDueNow: this.allTripsDueNow,
            isSquare: this.isSquare,
            isAuthNet: this.isAuthNet,
            isBusifyPay: this.isBusifyPay,
            defaultPaymentGateway: this.defaultPaymentGateway,
            sessionId: this.sessionId,
            isActive: this.tabIndex === order[method.paymentMethodType.key],
          },
        }
      })
      .sort((a, b) => order[a.key] - order[b.key])
  }

  get allTripsDueNow(): boolean {
    return this.quote.trips.findIndex((trip) => trip.dueDate !== null) === -1
  }

  get isSquare(): boolean {
    return this.defaultPaymentGateway?.paymentGatewayTypeKey === 'square'
  }

  get isAuthNet(): boolean {
    return this.defaultPaymentGateway?.paymentGatewayTypeKey === 'auth_net'
  }

  get isBusifyPay(): boolean {
    return this.defaultPaymentGateway?.paymentGatewayTypeKey === 'busify_pay'
  }

  get type(): string {
    return this.quote.sendAsInvoice ? 'Invoice' : 'Quote'
  }

  get paymentTypeTabs(): string[] {
    let tabs = []
    if (this.quote.enablePayLater) {
      tabs = ['Pay Now', 'Pay Later']
    }
    return tabs
  }

  get totalDueNow(): number {
    return this.quote.trips.reduce(
      (amnt, trip) => currency(getRecurringAmountDueNow(trip)).add(amnt).value,
      0
    )
  }

  displayTripDueDate(trip: Trip): string {
    return this.formatMediumDate(trip.dueDate)
  }

  handleSwitchInput(label: string): void {
    this.switchValue = label
  }

  async handleSubmitPayment(): Promise<void> {
    goTo(0) // Scroll to top
    await this.loadQuote()
  }

  setQuoteHeaderMessage(): void {
    if (this.isConverted) {
      this.statusHeader = 'Your Reservation is Confirmed'
      this.statusSubheader = 'Confirmation was sent to your email.'
    }
  }

  async loadQuote(): Promise<void> {
    const quoteHash = this.$route.params.id
    if (!quoteHash) {
      return
    }

    const [{ data: quote }, { data: transactions }] = await Promise.all([
      client.byHash(quoteHash),
      client.getTransactions(quoteHash),
    ])

    this.quote = quote
    this.transactions = transactions
    this.isConverted = this.quote.isConverted
    this.terms = this.quote.terms
    this.setQuoteHeaderMessage()
    if (this.quote.enablePONumber) {
      this.handleSwitchInput('Pay Later')
      if (!this.quote.poNumber) {
        this.editPONumber = true
      }
    }

    if (this.quote.company && !auth.getCompany) {
      auth.setCompany(this.quote.company)
    }
  }

  async loadCompanyPaymentGateway(): Promise<void> {
    const companyId = this.quote.company.companyId
    const { data } = await payments.getDefaultPaymentGateway(companyId)
    if (data.paymentGateways?.length) {
      this.defaultPaymentGateway = data.paymentGateways[0]
    }

    this.sessionId = v4()
  }

  handleSubmitFailure(errorMessage: string): void {
    this.displayErrorSnackbar = true
    goTo(0)
    this.errorMessages = errorMessage
  }

  handleConversionFailure(): void {
    this.isConversionError = true
    goTo(0)
  }

  loadCompanyBranding(): void {
    const { company } = this.quote
    const faviconFile = company?.faviconUrl || company?.darkLogoUrl
    window.document.title = `${company?.name || ''} - Checkout`
    if (faviconFile) {
      const icon = useFavicon()
      const faviconUrl = staticResource(faviconFile)
      icon.value = faviconUrl
    }
  }

  async mounted(): Promise<void> {
    EventBus.$on('checkout:convert-quote-success', this.handleSubmitPayment)
    EventBus.$on('checkout:convert-quote-error', this.handleSubmitFailure)
    EventBus.$on(
      'checkout:convert-quote-payment-error',
      this.handleConversionFailure
    )

    this.statusLoading = true
    await this.loadQuote()
    await this.loadCompanyPaymentGateway()
    this.statusLoading = false
    this.loadCompanyBranding()
  }

  beforeDestroy(): void {
    EventBus.$off('checkout:convert-quote-success', this.handleSubmitPayment)
    EventBus.$off('checkout:convert-quote-error', this.handleSubmitFailure)
    EventBus.$off(
      'checkout:convert-quote-payment-error',
      this.handleConversionFailure
    )
  }
}
