var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.loading)?_c('div',{staticClass:"margin-t-4"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.color,"size":"65"}})],1):(_vm.header || _vm.isWarning)?_c('div',{staticClass:"d-flex flex-column justify-center align-center margin-b-2",class:{
      'margin-t-6': !_vm.isWarning,
      'margin-t-29': _vm.isWarning,
    }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"rounded-circle d-flex align-center justify-center margin-b-3",class:{
        'background-green-10': !_vm.isWarning,
        'background-rust-10': _vm.isWarning,
      },style:({
        width: '70px',
        height: '70px',
      })},[(!_vm.isWarning)?_c('CUIcon',{staticClass:"margin-t-3 margin-l-2",attrs:{"aria-label":"Success","width":"72px","height":"52px","color":"green"}},[_vm._v(" confirm ")]):_c('CUIcon',{attrs:{"aria-label":"Expired","width":"34px","height":"34px","color":"rust"}},[_vm._v(" alarm_clock ")])],1),_c('h1',[_vm._v(_vm._s(_vm.headerText))]),_c('p',{staticClass:"font-16 margin-t-2 margin-b-0 text-center",style:({ maxWidth: '476px' })},[_vm._v(" "+_vm._s(_vm.subheadingText)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }