
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { states } from '@/utils/states'
import { InstrumentType, PaymentMethod } from '@/utils/enum'
import { EventBus } from '@/utils/eventBus'
import { CheckoutQuote } from '@/models/dto/Quote'
import CheckoutPayNow from '@/components/CheckoutPayNow.vue'
import { PaymentGateway } from '@/services/paymentGatewayService'
import { getCardType } from '@/utils/payment'
import {
  CompanyPaymentGatewayDetail,
  PaymentGatewayConvertPayload,
  SquareCardFormPaymentInfo,
  TokenizeCreditCardPayload,
} from '@/models/PaymentGateway'
import SquareCardForm from '@/components/SquareCardForm.vue'
import quotes from '@/services/quotes'
import { isNotEmptyInput, isCardNumberValidLength } from '@/utils/validators'
import {
  Address,
  PaymentMethodKeys,
  PaymentMethodTypes,
  PaymentTypeIdKeys,
} from '@/models/dto'
import { Terms } from '@/models/dto/Terms'
import AutocompleteAddress from './AutocompleteAddress.vue'
import { formatFullName, currencyFilter } from '@/utils/string'
import currency from 'currency.js'
import { CheckoutDocument } from '@/models/dto/CheckoutDocument'
import CheckoutTermsModal from '@/components/CheckoutTermsModal.vue'
import dayjs from 'dayjs'
import { busifyPayBaseUrl, currentBaseUrl } from '@/utils/env'
import BusifyPayQuoteConvertForm from '@/components/BusifyPayQuoteConvertForm.vue'
import { WindowMessage } from '@/models/Message'
import PaymentLoadingScreen from '@/components/PaymentLoadingScreen.vue'
import { PaymentInstrument } from '@/models/dto/PaymentProfile'
@Component({
  components: {
    CheckoutPayNow,
    SquareCardForm,
    AutocompleteAddress,
    CheckoutTermsModal,
    BusifyPayQuoteConvertForm,
    PaymentLoadingScreen,
  },
})
export default class CheckoutPaymentMethod extends Vue {
  @Prop({ required: true }) readonly quote: CheckoutQuote
  @Prop({ required: true }) readonly terms: Terms[]
  @Prop({ required: true }) readonly method: PaymentMethodTypes
  @Prop({ required: true }) readonly totalDueNow: number
  @Prop({ required: true }) readonly allTripsDueNow: boolean
  @Prop({ required: true }) readonly isSquare: boolean
  @Prop({ required: true }) readonly isAuthNet: boolean
  @Prop({ required: true }) readonly isBusifyPay: boolean
  @Prop({ required: true }) readonly isActive: boolean
  @Prop({ required: false })
  readonly defaultPaymentGateway!: CompanyPaymentGatewayDetail
  @Prop({ required: false }) readonly sessionId!: string

  PaymentMethod = PaymentMethod
  states = states
  currencyFilter = currencyFilter

  isNotEmptyInput = isNotEmptyInput
  isCardNumberValidLength = isCardNumberValidLength

  submitBusifyPayForm(): void {
    let busifyPayForm: any = null
    if (this.isMethodCard) {
      busifyPayForm = this.$refs['checkout-busify-pay-card-form']
    } else if (this.isMethodACH) {
      busifyPayForm = this.$refs['checkout-busify-pay-ach-form']
    }
    if (!busifyPayForm) {
      console.error('Could not find the Busify Pay payment form')
      return
    }

    busifyPayForm.submit()
  }

  isSubmitting = false
  info = {
    type: null,
    firstName: null,
    lastName: null,
    accountType: null,
    account: null,
    confirmAccount: null,
    routing: null,
    number: null,
    expiration: null,
    cvc: null,
    address: {
      street: null,
      state: null,
      zip: null,
    },
  }
  amountPaid = 0
  checkoutDocument: CheckoutDocument = null
  paymentErrorMessages = []
  fullAddress: Partial<Address> = null
  showTermsModal = false
  termsAccepted = false
  termsError = false
  payFullAmount = false

  @Watch('info', { deep: true })
  onPaymentInfoChange(): void {
    this.paymentErrorMessages = []
  }

  get shouldDisplayBuyerInfoForm(): boolean {
    return this.isMethodCard && !this.isBusifyPay
  }

  get isMethodCard(): boolean {
    return this.method === PaymentMethod.Card
  }

  get isMethodACH(): boolean {
    return this.method === PaymentMethod.ACH
  }

  get isMethodCheck(): boolean {
    return this.method === PaymentMethod.Check
  }

  get isMethodWire(): boolean {
    return this.method === PaymentMethod.Wire
  }

  get isMethodOther(): boolean {
    return this.method === PaymentMethod.Other
  }

  get apiKey(): string {
    return this.defaultPaymentGateway?.apiKey
  }

  get processingFee(): number {
    const paymentMethod = this.quote.paymentMethods?.find(
      (method) => method.paymentMethodType.key === this.method
    )
    return paymentMethod?.processingFee || 0
  }

  get methodNote(): string {
    const paymentMethod = this.quote.paymentMethods?.find(
      (method) => method.paymentMethodType.key === this.method
    )
    let defaultNote = 'Payment instructions will be shared separately.'
    if (
      paymentMethod?.paymentMethodType?.key === PaymentMethodKeys.CREDIT_CARD
    ) {
      defaultNote = null
    } else if (
      paymentMethod?.paymentMethodType?.key === PaymentMethodKeys.ACH &&
      this.isBusifyPay
    ) {
      defaultNote = null
    }
    return paymentMethod?.note || defaultNote
  }

  get confirmationEmails(): string[] {
    return this.quote?.customer?.email ? [this.quote.customer.email] : []
  }

  get color(): string {
    return this.quote.company.primaryColor
  }

  get requireSignature(): boolean {
    return this.quote.requireSignatureUponCheckout
  }

  get allowPayMore(): boolean {
    if (!this.quote?.enablePayFullAmountNow) {
      return false
    }

    if (this.isMethodCard) {
      return true
    }

    if (this.isBusifyPay && this.isMethodACH) {
      return true
    }

    return false
  }

  get grandRecurringTotal(): number {
    return this.quote?.grandRecurringTotal || 0
  }

  handleMessageEvent(event: MessageEvent): void {
    if (!this.isActive) {
      return
    }

    const { origin, data }: { origin: string; data: WindowMessage } = event
    if (origin !== busifyPayBaseUrl()) {
      return
    }

    if (data.messageName === 'payment-failure') {
      this.handleBusifyPayError(data.messageData)
    } else if (data.messageName === 'payment-success') {
      this.handleBusifyPaySuccess(event.data)
    } else {
      this.handleBusifyPayInvalidForm()
    }
  }

  handleBusifyPayError(errorMessage: Record<string, unknown>): void {
    this.isSubmitting = false
    const message =
      errorMessage?.statusCode === 400
        ? errorMessage?.errorMessage
        : 'There was an error processing your payment. Please confirm all your information is correct and try again.'
    EventBus.$emit('snackbar:error', message)
  }

  handleBusifyPayInvalidForm(): void {
    this.isSubmitting = false
  }

  async handleBusifyPaySuccess(message: WindowMessage): Promise<void> {
    let description = ''
    const { paymentId } = message.messageData as any
    const { brand, instrument_type, last_four } = message.messageData
      ?.instrument as PaymentInstrument
    if (instrument_type === InstrumentType.CARD) {
      description = `${brand} - ${last_four}`
    } else {
      description = 'Online Bank'
    }

    const payment_method = this.isMethodCard ? 'credit_card' : 'ach'

    const payload = {
      quoteId: this.quote.quoteId,
      paymentInfo: null,
      payment_type:
        this.amountPaid === this.quote.grandRecurringTotal
          ? PaymentTypeIdKeys.FULL_PAYMENT
          : PaymentTypeIdKeys.DOWN_PAYMENT,
      amountPaid: this.amountPaid,
      paymentMethodData: null,
      payment_method,
      confirmationEmails: this.confirmationEmails,
      processingFeePercentage: this.processingFee,
      checkoutDocument: this.checkoutDocument,
      description,
      paymentId,
      sessionId: this.sessionId,
    }

    try {
      const res = await quotes.convertQuoteWithBusifyPay(payload)
      if (res.status === 200) {
        EventBus.$emit('checkout:convert-quote-success')
      } else {
        throw new Error('Error converting quote')
      }
    } catch (e) {
      EventBus.$emit('checkout:convert-quote-payment-error')
    } finally {
      this.isSubmitting = false
    }
  }

  validateForm(): void {
    const paymentForm: any = this.$refs['payment-info-form']
    if (!paymentForm.validate()) {
      this.isSubmitting = false
      return
    }
    if (this.requireSignature && this.isSquare && this.isMethodCard) {
      this.handlePaymentSubmit()
      return
    }
    if (this.requireSignature) {
      this.showTermsModal = true
      return
    }
    if (!this.termsAccepted) {
      this.termsError = true
      return
    }

    this.handlePaymentSubmit()
  }

  async handlePaymentSubmit(
    checkoutDocument?: CheckoutDocument
  ): Promise<void> {
    this.isSubmitting = true

    if (this.requireSignature && checkoutDocument) {
      this.checkoutDocument = checkoutDocument
    }

    if (this.payFullAmount) {
      this.amountPaid = currency(this.quote.grandRecurringTotal)
        .multiply(100 + this.processingFee)
        .divide(100).value
    } else {
      this.amountPaid = currency(this.totalDueNow)
        .multiply(100 + this.processingFee)
        .divide(100).value
    }

    if (this.isMethodCard && !this.isSquare && !this.isBusifyPay) {
      const authNetTokenizePayload = this.buildAuthNetTokenizePayload()
      const tokenizedCardInfo: PaymentGatewayConvertPayload =
        await this.tokenizeAuthNetCard(authNetTokenizePayload)
      await this.convertQuoteWithCard(tokenizedCardInfo, authNetTokenizePayload)
    } else if (this.isMethodCard && this.isSquare) {
      // If paying with a card thru Square, use a ref to trigger the payment form
      // to tokenize the card info
      // Remaining submit functionality comes through in handleSquareNonce
      await this.tokenizeSquareCard()
      return
    } else if (this.isBusifyPay && (this.isMethodCard || this.isMethodACH)) {
      this.submitBusifyPayForm()
      return
    } else {
      // We're paying through Check, ACH, or Other
      await this.convertQuoteManually()
    }
    this.isSubmitting = false
  }

  handleCheckboxInput(input: boolean): void {
    this.termsError = false
    this.termsAccepted = input
  }

  handleShowTerms(): void {
    this.termsAccepted = true
    this.showTermsModal = true
  }

  handleCloseTerms(): void {
    this.showTermsModal = false
  }

  handleCancelTerms(): void {
    this.showTermsModal = false
    this.termsAccepted = false
  }

  handleAcceptTerms(fullName: string, email: string): void {
    this.showTermsModal = false
    this.termsAccepted = true
    this.termsError = false

    const checkoutDocument: CheckoutDocument = {
      signatureName: fullName,
      signatureEmail: email,
      signatureTimestamp: dayjs.utc().toISOString(),
    }
    if (this.requireSignature) {
      this.handlePaymentSubmit(checkoutDocument)
    }
  }

  async convertQuoteWithCard(
    tokenizedCardInfo: PaymentGatewayConvertPayload,
    paymentMethodInfo: TokenizeCreditCardPayload
  ): Promise<void> {
    try {
      const res = await quotes.convertQuoteWithTokenizedPayment({
        quoteId: this.quote.quoteId,
        paymentInfo: tokenizedCardInfo,
        payment_type:
          this.amountPaid === this.quote.grandRecurringTotal
            ? PaymentTypeIdKeys.FULL_PAYMENT
            : PaymentTypeIdKeys.DOWN_PAYMENT,
        amountPaid: this.amountPaid,
        paymentMethodData: paymentMethodInfo,
        confirmationEmails: this.confirmationEmails,
        processingFeePercentage: this.processingFee,
        checkoutDocument: this.checkoutDocument,
      })
      if (res.status === 200) {
        EventBus.$emit('checkout:convert-quote-success')
      }
    } catch (e: any) {
      this.isSubmitting = false
      EventBus.$emit('checkout:convert-quote-error', e.response.data.message)
    }
  }

  async convertQuoteManually(): Promise<void> {
    try {
      const res = await quotes.convertManually({
        quoteId: this.quote.quoteId,
        payment_method: this.method,
        payment_type: PaymentTypeIdKeys.BILL_AFTER_SERVICES,
        amountPaid: this.amountPaid,
        confirmationEmails: this.confirmationEmails,
        checkoutDocument: this.checkoutDocument,
      })
      if (res.status === 200) {
        EventBus.$emit('checkout:convert-quote-success')
      }
    } catch (e: any) {
      this.isSubmitting = false
      EventBus.$emit('checkout:convert-quote-error', e.response.data.message)
    }
  }

  handleChangeAddressInput(input: string): void {
    this.info.address = { ...this.info.address, street: input }
    this.fullAddress = { ...this.fullAddress, street1: input }
  }

  handleChangeAddress(address: Address): void {
    if (address) {
      this.info = {
        ...this.info,
        address: {
          street: address.street1,
          state: address.state,
          zip: address.postalCode,
        },
      }
      this.fullAddress = address
    } else {
      this.info.address = null
      this.fullAddress = null
    }
  }

  async tokenizeAuthNetCard(
    payload: TokenizeCreditCardPayload
  ): Promise<PaymentGatewayConvertPayload> {
    const paymentGatewayService = new PaymentGateway()
    let tokenizedCreditCardInfo: any
    try {
      tokenizedCreditCardInfo = await paymentGatewayService.tokenize(
        payload,
        this.quote
      )
    } catch (e) {
      const errors = e as string[]
      this.paymentErrorMessages = errors
      this.isSubmitting = false
      return
    }

    return tokenizedCreditCardInfo
  }

  buildAuthNetTokenizePayload(): TokenizeCreditCardPayload {
    const cardType = getCardType(this.info.number)
    const cardNumber = this.info.number.split(' ').join('')
    const paymentMethodData: TokenizeCreditCardPayload = {
      activeMethod: 'credit_card',
      name: formatFullName(this.info),
      cardNumber,
      mask: this.info.number.slice(-4),
      securityCode: this.info.cvc,
      exp_date: this.info.expiration,
      expirationMonth: this.info.expiration.split('/')[0],
      expirationYear: `${this.info.expiration.split('/')[1]}`,
      type_label: cardType,
      address: {
        street1: this.info.address.street,
        street2: '',
        city: null,
        state: this.info.address.state,
        postal_code: this.info.address.zip,
        name: '',
        lat: null,
        lng: null,
        title: null,
        country: null,
      },
    }

    return paymentMethodData
  }

  handleSquareError(): void {
    this.isSubmitting = false
  }

  async tokenizeSquareCard(): Promise<void> {
    const squarePaymentForm: any = this.$refs['square-payment-form']
    if (!squarePaymentForm) {
      return
    }
    try {
      await squarePaymentForm.requestCardNonce()
    } catch {
      return
    }
  }

  // Seems like the only way to verify a square payment form is to
  // attempt to tokenize the payment?

  // If we're requiring a signature during the checkout, check whether
  // we're tokenizing the form as a part of the validation process before
  // taking a signature, or as a part of actually handling payment. In the former
  // case, termsAccepted will be false
  async handleSquareNonce(
    paymentInfo: SquareCardFormPaymentInfo
  ): Promise<void> {
    if (this.requireSignature && !this.termsAccepted) {
      this.showTermsModal = true
      this.isSubmitting = false
      return
    }

    const { nonce } = paymentInfo
    const tokenizedPaymentInfo: PaymentGatewayConvertPayload = {
      tokens: [nonce],
      paymentGateway: {
        key: 'square',
        id: this.defaultPaymentGateway.companyPaymentGatewayId,
        clientId: this.defaultPaymentGateway.clientKey,
      },
    }

    const paymentMethodData: TokenizeCreditCardPayload = {
      activeMethod: 'credit_card',
      name: '',
      cardholderName: '',
      cardNumber: '',
      securityCode: '',
      mask: paymentInfo.last_4,
      type_label: paymentInfo.card_brand,
      ...paymentInfo,
    }

    await this.convertQuoteWithCard(tokenizedPaymentInfo, paymentMethodData)
    this.isSubmitting = false
  }

  mounted(): void {
    this.info.type = this.method
  }

  created(): void {
    window.addEventListener('message', this.handleMessageEvent)
  }

  beforeDestroy(): void {
    window.removeEventListener('message', this.handleMessageEvent)
  }
}
