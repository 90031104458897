
import { CheckoutQuote } from '@/models/dto/Quote'
import { countryPhoneFormatFilter, formatFullName } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CheckoutInformation extends Vue {
  @Prop({ required: true }) quote: CheckoutQuote

  formatFullName = formatFullName

  get phoneNumber(): string {
    let phoneNumber = countryPhoneFormatFilter(
      this.quote.customer.phone,
      this.quote.customer.phoneCountryKey
    )
    if (this.quote.customer.phoneExtension) {
      phoneNumber = `${phoneNumber} ext. ${this.quote.customer.phoneExtension}`
    }
    return phoneNumber
  }
}
