var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"background-gray-bg min-h-full checkout-ui-a-tag"},[(_vm.quote)?_c('div',{staticClass:"d-flex flex-column align-center flex-grow-1",class:{ 'margin-x-9': _vm.$vuetify.breakpoint.width >= 650 },style:({ minHeight: '100vh' })},[_c('CheckoutAppBar',{attrs:{"quote":_vm.quote}}),_c('CUSnackbar',{attrs:{"message":_vm.errorMessages,"timeout":5000,"error":""},model:{value:(_vm.displayErrorSnackbar),callback:function ($$v) {_vm.displayErrorSnackbar=$$v},expression:"displayErrorSnackbar"}}),_c('CheckoutStatus',{attrs:{"header":_vm.statusHeader,"subheader":_vm.statusSubheader,"is-expired":_vm.isExpired,"is-sold-out":_vm.isSoldOut,"is-conversion-error":_vm.isConversionError,"loading":_vm.statusLoading,"color":_vm.quote.company.primaryColor}}),(!_vm.isExpired && !_vm.isSoldOut)?_c('section',{staticClass:"padding-t-10 padding-b-12 w-full d-flex justify-space-between flex-grow-1 flex-shrink-0 flex-wrap",style:({ maxWidth: '1220px' })},[(_vm.$vuetify.breakpoint.width >= 650)?_c('div',{class:{
          'margin-r-5': _vm.$vuetify.breakpoint.width >= 1200,
          'max-w-632 w-full margin-x-auto':
            _vm.$vuetify.breakpoint.width < 1200 &&
            _vm.$vuetify.breakpoint.width >= 650,
        }},[_c('h2',{staticClass:"font-bolder"},[_vm._v(_vm._s(_vm.type)+" Summary")]),_c('CheckoutInformation',{attrs:{"quote":_vm.quote}}),_c('div',{staticClass:"w-full max-w-632 background-white rounded-xl padding-x-6 padding-y-5 margin-t-5 box-shadow-medium"},[_c('h3',{staticClass:"font-bold"},[_vm._v("Trip Details")]),_c('CUTabs',{attrs:{"is-sub-tab":"","tabs":_vm.tripTabs,"transparent-background":"","align-left":"","color":_vm.quote.company.secondaryColor}})],1)],1):_c('div',{staticClass:"w-full background-white padding-x-6 margin-b-6 margin-t-n3 box-shadow-medium"},[_c('CUExpansionPanel',{attrs:{"arrow-width":"25px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"font-bolder"},[_vm._v("Quote Summary")])]},proxy:true}],null,false,1423746963)},[_c('CheckoutInformation',{attrs:{"quote":_vm.quote}}),_c('div',{staticClass:"w-full padding-t-3 padding-b-6"},[_c('h3',{staticClass:"font-bold"},[_vm._v("Trip Details")]),_c('CUTabs',{attrs:{"is-sub-tab":"","tabs":_vm.tripTabs,"transparent-background":"","align-left":"","color":_vm.quote.company.secondaryColor}})],1)],1)],1),_c('div',{class:{
          'w-468': _vm.$vuetify.breakpoint.width >= 1200,
          'w-628 margin-x-auto':
            _vm.$vuetify.breakpoint.width < 1200 &&
            _vm.$vuetify.breakpoint.width >= 650,
          'w-full': _vm.$vuetify.breakpoint.width < 650,
        }},[(_vm.$vuetify.breakpoint.width >= 650)?_c('h2',{staticClass:"font-bolder margin-b-5",class:{ 'margin-t-12': _vm.$vuetify.breakpoint.width < 1200 }},[(!_vm.isConverted)?_c('span',[_vm._v("Confirm")]):_vm._e(),_vm._v(" Your Booking ")]):_vm._e(),(_vm.$vuetify.breakpoint.width >= 650)?_c('CheckoutTotal',{attrs:{"quote":_vm.quote}}):_vm._e(),_c('div',{staticClass:"background-white padding-x-6 padding-y-5 box-shadow-medium",class:{
            'rounded-xl': _vm.$vuetify.breakpoint.width >= 650,
          }},[(_vm.$vuetify.breakpoint.width < 650)?_c('h2',{staticClass:"font-bolder margin-b-5"},[(!_vm.isConverted)?_c('span',[_vm._v("Confirm")]):_vm._e(),_vm._v(" Your Booking ")]):_vm._e(),(_vm.expiration)?_c('div',{staticClass:"d-flex align-center margin-b-4"},[_c('CUIcon',{staticClass:"margin-r-2",style:({
                color: _vm.quote.company.secondaryColor,
              }),attrs:{"aria-label":"Expiration","color":_vm.quote.company.secondaryColor,"height":"17","width":"17"}},[_vm._v(" clock ")]),_c('p',{staticClass:"font-14"},[_vm._v(" Book "),_c('span',{staticClass:"font-bold"},[_vm._v("by "+_vm._s(_vm.expiration))]),_vm._v(" to reserve your vehicles ")])],1):_vm._e(),_vm._l((_vm.quote.trips),function(trip,i){return _c('div',{key:`trip-due-${trip.tripId}-${i}`,class:{ 'margin-b-3': !_vm.allTripsDueNow }},[(!_vm.allTripsDueNow)?_c('div',{staticClass:"d-flex justify-space-between font-14"},[_c('span',[_vm._v(_vm._s(trip.routeName || `Trip ${i + 1}`)+" Due Now")]),_c('span',[_vm._v(" "+_vm._s(_vm.currencyFilter(_vm.getRecurringAmountDueNow(trip)))+" ")])]):_vm._e(),(trip.dueDate && _vm.getRecurringAmountDueLater(trip) > 0)?_c('div',{staticClass:"d-flex justify-space-between font-14"},[_c('span',[_vm._v(" "+_vm._s(trip.routeName || `Trip ${i + 1}`)+" Due "+_vm._s(_vm.displayTripDueDate(trip))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.currencyFilter(_vm.getRecurringAmountDueLater(trip)))+" ")])]):_vm._e()])}),(!_vm.allTripsDueNow)?_c('div',{staticClass:"d-flex justify-space-between font-bold",class:{
              'margin-b-4': !_vm.isConverted,
            }},[_c('span',[_vm._v("Total Due Now")]),_c('span',[_vm._v(_vm._s(_vm.currencyFilter(_vm.totalDueNow)))])]):_vm._e(),(!_vm.isConverted && _vm.totalDueNow > 0)?[(_vm.$vuetify.breakpoint.width < 650)?_c('CheckoutTotal',{attrs:{"quote":_vm.quote}}):_vm._e(),(_vm.paymentTypeTabs.length && !_vm.quote.enablePONumber)?_c('div',{staticClass:"d-flex margin-b-5"},[_c('CUMultiSwitch',{staticClass:"border-1 border-solid border-gray-border-mid",attrs:{"labels":_vm.paymentTypeTabs,"value":_vm.switchValue,"color":_vm.quote.company.primaryColor,"bold-selected":"","wide":""},on:{"input":_vm.handleSwitchInput}})],1):_vm._e(),(_vm.switchValue === 'Pay Now')?_c('CUTabs',{staticClass:"margin-b-7",class:!_vm.allTripsDueNow ? 'margin-t-4' : 'margin-t-n2',attrs:{"is-sub-tab":"","tabs":_vm.paymentMethodTabs,"transparent-background":"","align-left":"","color":_vm.quote.company.secondaryColor},on:{"tab:change":function($event){_vm.tabIndex = $event[0]}}}):_vm._e()]:_vm._e(),(_vm.quote.enablePONumber)?_c('div',{staticClass:"d-flex margin-t-4 font-14"},[_c('b',[_vm._v("PO Number")]),(!_vm.editPONumber || _vm.isConverted)?[(_vm.quote.poNumber)?_c('span',{staticClass:"margin-l-2"},[_vm._v(" "+_vm._s(_vm.quote.poNumber)+" ")]):_vm._e(),(!_vm.isConverted)?_c('CUButton',{staticClass:"align-center margin-l-2 justify-end hover:text-primary",attrs:{"icon":"","small":"","width":"18","height":"18"},on:{"click":function($event){_vm.editPONumber = true}}},[_c('CUIcon',{attrs:{"color":"bg-gray-4","width":"18","height":"18"}},[_vm._v("edit")])],1):_vm._e()]:_vm._e()],2):_vm._e(),(_vm.editPONumber && !_vm.isConverted)?_c('CUTextField',{staticClass:"margin-t-2",attrs:{"hide-details":""},model:{value:(_vm.quote.poNumber),callback:function ($$v) {_vm.$set(_vm.quote, "poNumber", $$v)},expression:"quote.poNumber"}}):_vm._e(),(
              !_vm.isConverted &&
              (_vm.switchValue === 'Pay Later' || _vm.totalDueNow === 0)
            )?_c('CheckoutPayLater',{staticClass:"margin-t-6 margin-b-7",attrs:{"quote":_vm.quote,"terms":_vm.terms,"is-zero-checkout":_vm.totalDueNow === 0 && !_vm.quote.enablePONumber}}):_vm._e(),(!_vm.isConverted && _vm.paymentPolicy)?_c('div',[_c('p',{staticClass:"font-bold font-16 margin-b-2"},[_vm._v("Payment Policy")]),_c('div',{staticClass:"margin-b-1 blue-link"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.paymentPolicy)}})])]):_vm._e()],2),(
            _vm.quote &&
            _vm.isConverted &&
            _vm.transactions.length &&
            _vm.$vuetify.breakpoint.width >= 650
          )?_c('CheckoutPaymentOverview',{staticClass:"margin-t-10",attrs:{"transactions":_vm.transactions,"payment-policy":_vm.paymentPolicy,"total-due-now":_vm.totalDueNow,"quote-total":_vm.quote.grandRecurringTotal,"payment-type-id":_vm.quote.convertedPaymentMethodTypeId,"quote-payment-methods":_vm.quote.paymentMethods}}):_vm._e()],1)]):_c('div',{staticClass:"flex-grow-1"}),_c('CheckoutFooter',{style:({ maxWidth: '1220px' }),attrs:{"company":_vm.quote.company}})],1):_vm._e(),(!_vm.quote)?_c('CheckoutLoader'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }